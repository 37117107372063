import React, { useEffect, useState } from 'react'
import './Inbox.css'
import { Link, generatePath, useParams } from 'react-router-dom'
import axios from 'axios'
import translations from '../../../locales/translations.json'

function Inbox() {
  const userData = useParams()
  const [displayName, setDisplayName] = useState(true)
  const [followers, setFollowers] = useState([])
  const [messages] = useState([
    {
      from: {
        name: "Lubos",
        surname: "Mincak",
        email: "lubos.mincak@gmail.com",
        source:"https://backend.famsketch.eu/photos/static/profile_images/Ja.png",
        password: "Password",
        params: {
          user: "Lubos_Mincak",
          id: "946915224427"
        }
      },
      to: {
        user: "demo_account",
        id: "000000000000"
      },
      at: "26.03.2021 14:00",
      content: "Hey, How are you?"
    },
    {
      from: {
        name: "Peter",
        surname: "Kacvinsky",
        email: "peter@example.com",
        source: "https://backend.famsketch.eu/photos/static/profile_images/IMG_20240810_144324.jpg",
        password: "Password123",
        params: {
          user: "Peter_Kacvinsky",
          id: "857239102384"
        }
      },
      to: {
        user: "demo_account",
        id: "000000000000"
      },
      at: "27.03.2021 09:30",
      content: "Good morning! Are you available for a call?"
    },
    {
      from: {
        name: "Matej",
        surname: "Recky",
        email: "matej@example.com",
        source:"https://backend.famsketch.eu/photos/static/profile_images/FB_IMG_1728314910210.jpg",
        password: "EmmaPassword",
        params: {
          user: "Matej_Recky",
          id: "635192837465"
        }
      },
      to: {
        user: "demo_account",
        id: "000000000000"
      },
      at: "28.03.2021 17:45",
      content: "Hi! Just sent the documents you requested."
    },
    {
      from: {
        name: "Peter",
        surname: " Lehotsky",
        email: "Peter.lehostky@example.com",
        source:"https://backend.famsketch.eu/photos/static/profile_images/lego.jpg",
        password: "A1ic3Pass",
        params: {
          user: "Peter_Lehotsky",
          id: "746592031274"
        }
      },
      to: {
        user: "demo_account",
        id: "000000000000"
      },
      at: "29.03.2021 13:15",
      content: "Hey! Let's discuss the project update."
    }
  ])

  const getCookie = (name) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
    return null
  }
  
  const savedLanguage = getCookie('selectedLanguage')
  const initialLanguage = savedLanguage ? savedLanguage.toUpperCase() : undefined

  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage || 'EN')
  const [t, setT] = useState(translations[currentLanguage])

  // Fetch followers
  useEffect(() => {
    const fetchFollowers = async () => {
      try {
        const response = await axios.post('https://backend.famsketch.eu/user/getFollowers', {
          token: localStorage.getItem('token')
        })
        
        if (response.data && response.data.connections) {
          setFollowers(response.data.connections)
        }
      } catch (error) {
        console.error('Error fetching followers:', error)
      }
    }

    fetchFollowers()
  }, [])

  useEffect(() => {
    if (!savedLanguage) {
      document.cookie = `selectedLanguage=en; path=/`
    }
    setT(translations[currentLanguage])
  }, [currentLanguage, savedLanguage])

  function handlePannel(e) {
    e.target.parentElement.parentElement.querySelectorAll(".toggled").forEach(element => {
      if (element.classList.contains("hidden")) {
        element.classList.remove("hidden")
        element.classList.add("shown")
      } else if (element.classList.contains("shown")) {
        element.classList.remove("shown")
        element.classList.add("hidden")
      }
    })
  }

  return (
    <div className="app-element Inbox_content content">
      <section className="app-element pannel invisible-scrollbar" id="users">
        <button 
          type="submit" 
          onClick={(e) => setDisplayName(!displayName)} 
          className="app-element toggle" 
          id="toggle-pannel-left"
        >
          <i className="app-element bx bxs-right-arrow"></i>
        </button>
        {followers.map(follower => (
          <div 
            className="app-element user" 
            key={follower.username} 
            id={follower.username}
          >
            <img 
              alt={`${follower.first_name} ${follower.last_name}`}
              src={follower.image || "/profilePicture-default.png"} 
              className="app-element profile-picture default-profile-picture" 
            />
            <p className={`app-element toggled ${displayName ? "hidden" : "shown"} name`}>
              {`${follower.first_name} ${follower.last_name}`}
            </p>
            <div className="app-element user-activity" status="active"></div>
          </div>
        ))}
      </section>

      <section className="app-element center invisible-scrollbar">
        <div className="app-element links">
          <Link to={generatePath("/:user/inbox", userData)} className="app-element link">
            <i className="app-element link bx bx-video"></i>
          </Link>
          <Link to={generatePath("/:user/inbox", userData)} className="app-element link">
            <i className="app-element link bx bx-joystick"></i>
          </Link>
        </div>
        <div className="app-element" id="inbox">
          {messages.map((message, index) => (
            <article className="app-element message" key={index}>
              <header className="app-element info">
                <img 
                  alt='profilePicture-default' className='app-element profile-picture'
                  src={message.from.source || "/profilePicture-default.png"} 
                />
                <p className="app-element author">
                  {message.from.params.user.toLowerCase().replaceAll("_", " ")}
                </p>
                <div class="app-element user-activity" status="active"></div>
                <p className="app-element time">{message.at}</p>
              </header>
              <p className="app-element message-content">{message.content}</p>
            </article>
          ))}
        </div>
      </section>

      <section className="app-element pannel invisible-scrollbar" id="selected-user">
        <button type="submit" className="app-element toggle" id="toggle-pannel-right">
          <i onClick={e => handlePannel(e)} className="app-element bx bxs-left-arrow"></i>
        </button>
        <div className="app-element toggled hidden user-info">
          <img 
            src="/profilePicture-default.png" 
            className="app-element profile-picture default-profile-picture toggled hidden" 
          />
          <h2 className="app-element name toggled hidden">
            {userData.user.toLowerCase().replaceAll("_", " ")}
          </h2>
          <p className="app-element people icon-text-wrapper toggled hidden">
            <i className="app-element bx bxs-group"></i>
            <span id="number">{followers.length}</span>
          </p>
          <div className="app-element info toggled hidden">
            <p className="app-element birth info">
              <span className="app-element tag">{t.born}:</span> DD.MM.YYYY
            </p>
            <p className="app-element location info">
              <span className="app-element tag">{t.city}:</span> Košice
            </p>
            <p className="app-element relationship-status info">
              <span className="app-element tag">{t.status}:</span> {t.single}
            </p>
          </div>
          <div className="app-element social toggled hidden">
            <a href="" className="app-element function">
              <i className="app-element bx bx-male-female"></i>
            </a>
            <Link to="{name: 'user-id-calendar', params: userData}" className="app-element link">
              <i className="app-element bx bx-calendar"></i>
            </Link>
            <a href="" className="app-element function">
              <i className="app-element bx bx-heart"></i>
            </a>
            <a href="" className="app-element function">
              <i className="app-element bx bx-share-alt"></i>
            </a>
          </div>
          <p className="app-element quote toggled hidden">{t.memories_title}</p>
        </div>
      </section>
    </div>
  )
}

export default Inbox