import React, { useState, useEffect } from 'react'
import './Chronicle.css'
import { Link, generatePath, useParams } from 'react-router-dom'
import axios from 'axios'
import translations from '../../../locales/translations.json'

function Chronicle() {
  const [displayName, setDisplayName] = useState(true)
  const [followers, setFollowers] = useState([])
  const params = useParams()

  const getCookie = (name) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
    return null
  }
  
  const savedLanguage = getCookie('selectedLanguage')
  const initialLanguage = savedLanguage ? savedLanguage.toUpperCase() : undefined

  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage || 'EN')
  const [t, setT] = useState(translations[currentLanguage])

  // Fetch followers
  useEffect(() => {
    const fetchFollowers = async () => {
      try {
        const response = await axios.post('https://backend.famsketch.eu/user/getFollowers', {
          token: localStorage.getItem('token')
        })
        
        if (response.data && response.data.connections) {
          setFollowers(response.data.connections)
        }
      } catch (error) {
        console.error('Error fetching followers:', error)
      }
    }

    fetchFollowers()
  }, [])

  useEffect(() => {
    if (!savedLanguage) {
      document.cookie = `selectedLanguage=en; path=/`
    }
    setT(translations[currentLanguage])
  }, [currentLanguage, savedLanguage])

  return (
    <div className='app-element chronicle-content content'>
      <div className='app-element chronicle'>
        <div className='app-element datewise invisible-scrollbar'>
          <div className='date'>
            <button id='prev'>{'<'}</button>
            <p id='date'>{"Day 20, March 2021"}</p>
            <button id='next'>{'>'}</button>
          </div>
          <img 
            className='thumbnail' 
            alt='event-image' 
            src={currentLanguage === 'EN' ? '/chronicle_side_image.png' : '/chronicle_side_image.png'} 
          />
          <div className='date-note'>
            <p className='app-element participants-title'>{t.participants}</p>
            <div className='app-element participants'>
              {followers.length > 0 ? (
                followers.slice(0, 4).map((follower, index) => (
                  <img 
                    key={index}
                    className='participant'
                    src={follower.image || '/profilePicture-default.png'}
                    alt={`${follower.first_name} ${follower.last_name}`}
                    title={`${follower.first_name} ${follower.last_name}`}
                  />
                ))
              ) : (
                // Fallback placeholders if no followers
                Array(4).fill(null).map((_, index) => (
                  <img 
                    key={index}
                    className='participant'
                    src='/profilePicture-default.png'
                    alt='profilePicture-default'
                  />
                ))
              )}
            </div>
            <textarea 
              className="app-element plan-note invisible-scrollbar" 
              id="content" 
              placeholder="Add notes" 
            />
          </div>
        </div>
        <div className='app-element chronicle-data invisible-scrollbar'>
          <div className='app-element chronicle-title'>{t.wedding}</div>
          <div className='app-element chronicle-body invisible-scrollbar'>
            <p id='milestone_text'>{t.milestone}</p>
            <div id="imagess">
              <img 
                className='wedding' id="img1"
                alt='event-image' 
                src={currentLanguage === 'EN' ? '/chronicle2.png' : '/chronicle2.png'} 
              />
              <img 
                className='wedding' id="img2"
                alt='event-image' 
                src={currentLanguage === 'EN' ? '/chronicle1.png' : '/chronicle1.png'} 
              />
            </div>
            <p id='wedding_text'>{t.desc_2}</p>
            <div>
              <img 
                className='ring' 
                id='ring_image' 
                alt='event-image' 
                src={currentLanguage === 'EN' ? '/chronicle_image_3.png' : '/chronicle_image_3.png'} 
              />
              <p id='ring_text'>{t.desc_3}</p>
            </div>
          </div>
        </div>
      </div>
      <nav className="app-element sidebar-chronicle" id="sidebar">
        <button 
          onClick={(e) => setDisplayName(!displayName)} 
          className="app-element toggle" 
          id="toggle-pannel-left"
        >
          
          <i className="app-element bx bxs-right-arrow" />
        </button>
        <div className='flex-box'>
          <img 
            className={`app-element small-picture title ${displayName ? "hidden" : "shown"} toggled`}
            src='/ph_plus-fill.png' 
            alt='ph_plus-fill.png' 
          />

          <img 
            className={`app-element small-picture title ${displayName ? "hidden" : "shown"} toggled`}
            src='/mdi_heart-outline.png' 
            alt='/mdi_heart-outline.png' 
          />

          <img 
            className={`app-element small-picture title ${displayName ? "hidden" : "shown"} toggled`}
            src='/material-symbols_share-outline.png' 
            alt='/material-symbols_share-outline.png' 
          />
        </div>
        <Link to={generatePath("/u/:user/album", params)} className="app-element icon-text-wrapper link">
          <i className="app-element bx bxs-image"></i>
          <p className={`app-element title ${displayName ? "hidden" : "shown"} toggled`}>{t.photos}</p>
        </Link>
        <Link to={generatePath("/u/:user/album", params)} className="app-element icon-text-wrapper link">
          <i className="app-element bx bxs-music"></i>
          <p className={`app-element title ${displayName ? "hidden" : "shown"} toggled`}>{t.audio}</p>
        </Link>
        <Link to={generatePath("/u/:user/album", params)} className="app-element icon-text-wrapper link">
          <i className="app-element bx bxs-video"></i>
          <p className={`app-element title ${displayName ? "hidden" : "shown"} toggled`}>{t.video}</p>
        </Link>
        <Link to={generatePath("/u/:user/chronicle", params)} className="app-element icon-text-wrapper link">
          <i className="app-element bx bxs-book"></i>
          <p className={`app-element title ${displayName ? "hidden" : "shown"} toggled`}>{t.chronicle}</p>
        </Link>
        <Link to={generatePath("/u/:user/memorial", params)} className="app-element icon-text-wrapper link">
          <i className="app-element bx bxs-book-bookmark"></i>
          <p className={`app-element title ${displayName ? "hidden" : "shown"} toggled`}>{t.memorial}</p>
        </Link>
      </nav>
    </div>
  )
}

export default Chronicle